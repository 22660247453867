exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-bonsai-composer-js": () => import("./../../../src/pages/apps/bonsai-composer.js" /* webpackChunkName: "component---src-pages-apps-bonsai-composer-js" */),
  "component---src-pages-apps-bonsai-designer-js": () => import("./../../../src/pages/apps/bonsai-designer.js" /* webpackChunkName: "component---src-pages-apps-bonsai-designer-js" */),
  "component---src-pages-apps-index-js": () => import("./../../../src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-apps-local-bonsai-js": () => import("./../../../src/pages/apps/local-bonsai.js" /* webpackChunkName: "component---src-pages-apps-local-bonsai-js" */),
  "component---src-pages-apps-try-pots-online-js": () => import("./../../../src/pages/apps/try-pots-online.js" /* webpackChunkName: "component---src-pages-apps-try-pots-online-js" */),
  "component---src-pages-bonsai-design-styles-broom-js": () => import("./../../../src/pages/bonsai-design/styles/broom.js" /* webpackChunkName: "component---src-pages-bonsai-design-styles-broom-js" */),
  "component---src-pages-bonsai-images-js": () => import("./../../../src/pages/bonsai-images.js" /* webpackChunkName: "component---src-pages-bonsai-images-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

